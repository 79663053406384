// Ejecuta la función una vez que la página esté completamente cargada
window.onload = function() {
    // Código a ejecutar una vez que la página esté cargada
    
    // Crea una nueva instancia de IntersectionObserver
    const observer = new IntersectionObserver((entries) => {
        // Itera sobre las entradas y detecta cuando el elemento es visible
        entries.forEach((entry) => {
            if (entry.intersectionRatio > 0) {
                entry.target.classList.add('visible');
            } else {
                entry.target.classList.remove('visible');
            }
        });
    });

    // Selecciona todos los elementos que quieres observar
    const elements = document.querySelectorAll('.timeline__entry-text-right,.timeline__entry-text-left');
    
    // Itera sobre los elementos y comienza a observarlos
    elements.forEach((element) => {
        observer.observe(element);
    });
};

window.myFunction = function myFunction(){
    var x = document.querySelector("div.menu");
    if (x.className === "menu"){
        x.className += " responsive";
    } else {
        x.className = "menu";
    }
}

window.myPopUpFunction = function myPopUpFunction(e) {
    var popup = (e.target.className === 'nav__link') ? document.getElementById("myPopup") : document.getElementById("myPopup2");
    popup.classList.toggle("show");
    setTimeout(() =>{ popup.classList.toggle("show"); }, 2000);
}